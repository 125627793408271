
@include media-query($on-big) {
  .links1 {
    width: 47%;
  }

  .links2 {
    width: 47%;
  }
  .ss {
  margin: 0 40px 20px 20px;;
  }
  #lang {
    margin: 0 20px;
  }
}

