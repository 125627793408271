@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }

  .top {
    width: 100%;
  }
  
  .index1 {
    width: 95%;
  }
  
  .site-footer {
    width: 95%;
  }

  .ss{
    margin: 10px auto;
    width: 100%;
  }

  .section-link {
    display: block;
    width: 95%;
    min-width: 300px;
    height: auto;
    padding: 5px;
    margin: 30px auto 30px auto;
    .dload-icon {
      display: none;
    }
  }
  
  .section-name {
    margin: 10px 0 40px 0;
  }
  .header-title {
    width: 40%;
  }
}
