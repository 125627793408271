
h1 {
text-align: center;
}

.bolda a{
font-weight: bold; 
}

.section-name {
text-align: center; 
margin: 20px 0;
}

.ss {
width: calc(50% - 50px);
float: left;
}

.solution1 .links-wrapper .link {
  display: block;
  background-color: #ccc;
  margin: 0 0 10px 0;
  border-radius: 5px;
  text-decoration: none !important;
  left: 0;

  .dload {
    display: block;
    float: right;
    text-align: center;
    background-color: #eee;
    width: 100px;
    padding: 10px;
    margin: 20px 25px 0 0;
    border-radius: 5px;
    &:hover {
      background-color: #ffc900;
      color: #333;
    }
  }
  .dload-title {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 18px 5px 18px 25px;
    color: #3f3f3f;
  }
}

.index1 {
display: inline-block;
text-align: center;
width:  $content-width - ($spacing-unit * 2);
max-width:  $content-width - ($spacing-unit * 2);
}

.solution1 {
background-color: #eee;
width: 100%;
> div {
  width:  $content-width - ($spacing-unit * 2);
  margin: auto;
  clear: both;
  padding: 10px 0 10px 0;
}
padding: 10px 0 5px 0;
ul {
  list-style: outside disc;
  margin: 0 0 0 0;
  li {
    position: relative;
    left: $spacing-unit;
    margin-right: $spacing-unit;    
  }
}
p {
  margin-bottom: 5px;
}
.links-wrapper {
  @extend %clearfix;
}
}

.links0 {
background-color: #ccc; 
float: left; 
padding: 10px; 
height: auto; 
border-radius: 5px;
margin-right: 10px;
text-align: left;
width: 95%;
margin: 20px 0;
}

.links1 {
background-color: #ddd;
float: left; 
padding: 10px; 
height: auto; 
border-radius: 5px;
margin-right: 10px;
text-align: left;
}

.links2 {
background-color: #ccc; 
float: left; 
padding: 10px; 
height: auto; 
border-radius: 5px;
margin-right: 0;
text-align: left;
}

.section-link {
  background-color: #fefefe;
  color: #000 !important;
  text-decoration: none !important;

  border: 1px solid black; 
  border-radius: 5px;
  border-color: #cfcfcf;
  &:hover {
    border-color: #000;
  }

  display: inline-block;
  margin: 25px 10px 10px 10px;
  min-height: 225px;
  text-align: left;
  vertical-align: top;
  width: -webkit-calc(50% - 50px);
  width: calc(50% - 50px);
  max-width: $content-width - $spacing-unit * 2;
  font-size: 1.2rem;
  padding: 10px;

  .section-icon {
    display: block;
    float: left;
    width: 100px;
    margin: -30px 40px 0px 10px;
  }
  .dload-icon {
    display: block;
    float: right;
    width: 40px;
    padding: 10px 15px 0 0; 
  }
  .section-link-name {
      font-weight: bold;
      padding-top: 15px;
  }
  .section-link-descr {
      clear: both;
      padding: 20px 10px 10px 10px;
      hr {
        display: none;
      }
  }
}

.header-title {
  margin-left: 10px;
  margin-top: 10px;
  float: left;
  vertical-align:  middle;
}

.header-right {
  text-align: right;
  margin: 20px;
  float: right;
}

a {
  color: #000 ;
}
