@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }

  .top {
    width: 100%;
  }

  .section-link {
    width: 95%;
    height: auto;
  }

  .index1 {
    width: 100%;
  }

  .site-footer {
    width: 95%;
  }

  .solution1 {
    > div {
      width: 100%;
    }
  }

  .links1{
    width: 90%;
    float: none;
    margin: auto auto 10px 3%;
  }

  .links2{
    width: 90%;
    float: none;
    margin: auto auto auto 3%;
  }

  .ss {
    margin: 0 30px;
  }
}

